import React, { lazy, Suspense } from 'react';
import classes from './Preloader.module.scss';

const WeoPlayApp = lazy(() => import('../../WeoPlayApp'));

const Preloader = () => {
  const fallback = (
    <div className={classes.Preloader}>
      <div className={classes.animatedLogo} />
    </div>
  );
  return (
    <Suspense fallback={fallback}>
      <WeoPlayApp />
    </Suspense>
  );
};

export default Preloader;
